import { apiExamStop } from "@/api/h5";
import { setToken, getToken, removeToken, removeTokenl, getTokenl, setTokenl } from "@/utils/auth";
import { getUserInfo, category, apiDailyContractDetail } from "@/api/receipt";
import { apiListMeta } from "@/api/BASE-API";
import Cookie from "js-cookie";
import dayjs from "dayjs";

export default {
  /**
   * s： 表示字符串String
   * i： 表示整型Int（它是Number中的整数类型）
   * fl： 表示浮点Float（它是Number中的小数类型）
   * b： 表示布尔Boolean
   * a： 表示数组Array
   * o： 表示对象Object
   * fn： 不示函数FuncTIon
   * re： 表示正则Regular Expression
   *
   * */
  data () {
    return {
      isCompoundAuth: false,
      listMeta2: {},
      toAndFrom: {
        n: {},
        o: {}
      },
      ePageName: "",
      dataConfig: {
        categories: []
      }
    };
  },
  created () {
    if (getToken() && this.ePageName !== "login") {
      // this.getCategoryPromise()
    }
  },
  computed: {
    gzg () {
      let filed_ = "receiving_zg";
      if (this.listMeta2 && this.listMeta2.hasOwnProperty(filed_) && this.listMeta2[filed_].length > 0) {
        return Number(this.listMeta2[filed_][0].field_value) || 0;
      }
      return 0;
    },
    gain () {
      let filed_ = "is_receipt_admin";
      if (this.listMeta2 && this.listMeta2.hasOwnProperty(filed_) && this.listMeta2[filed_].length > 0) {
        return Number(this.listMeta2[filed_][0].field_value) || 0;
      }
      return 0;
    },
    gsain () {
      let filed_ = "is_super_admin";
      //&& ['development'].includes(process.env.NODE_ENV)
      if (this.user && this.user.hasOwnProperty(filed_)) {
        return Number(this.user[filed_]) === 1;
      }
      return false;
    },
    is_high_auth () {
      return [this.gain, this.gzg].includes(this.user.receipt_role) || this.gsain;
    },
    is_zg_staff () {
      let ain = [this.gain].includes(this.user.receipt_role);
      let sain = this.gsain;
      let zg = [this.gzg].includes(this.user.receipt_role);
      // 填写权限
      // 总工和项目人员,以及潜在的超级管理员
      return {
        is_ain_sain: ain || sain,
        is_ain: ain,
        is_sain: sain,
        is_zg: zg,
        is_staff: !(ain || sain || zg),
        is_write: sain || zg || !(ain || sain || zg)
      };
    },
    is_zg_super () {
      return [this.gzg].includes(this.user.receipt_role) || this.gsain;
    },
    is_yg_super () {
      return ![this.gzg].includes(this.user.receipt_role) || this.gsain;
    },
    bWrite () {
      return {
        // 填写权限,除了工管中心的都可以填写
        b135: ![this.gain].includes(this.user.receipt_role),
        b35: [this.gzg].includes(this.user.receipt_role) || this.gsain
      };
    },
    // 名称 V2
    getCommonNameLabel: {
      get () {
        let propFile = this.dataPropField || "params";
        if ([4].includes(this[propFile].contract_category)) {
          return "材料名称";
        } else if ([3].includes(this[propFile].contract_category)) {
          return "子目项目";
        } else if ([6].includes(this[propFile].contract_category)) {
          return "名称";
        } else {
          return "分部分项项目名称";
        }
      },
      set () {
      }
    },
    // 规格型号 V2
    getSpecificationLabel: {
      get () {
        let propFile = this.dataPropField || "params";
        if ([4, 6].includes(this[propFile].contract_category)) {
          return "规格型号";
        } else if ([3].includes(this[propFile].contract_category)) {
          return "机械设备规格型号";
        } else {
          return "型号";
        }
      },
      set () {
      }
    },
    // 单位 V2
    getUnitLabel: {
      get () {
        let propFile = this.dataPropField || "params";
        if ([4].includes(this[propFile].contract_category)) {
          return "单位";
        } else if ([3].includes(this[propFile].contract_category)) {
          return "计价单位";
        } else {
          return "单位";
        }
      },
      set () {
      }
    },
    // 数量 V2
    getQuantityList: {
      get () {
        let propFile = this.dataPropField || "params";
        if ([3, 4].includes(this[propFile].contract_category)) {
          return "暂定数量";
        } else if ([6].includes(this[propFile].contract_category)) {
          return "数量（暂定）";
        } else {
          return "暂定数量";
        }
      },
      set () {
      }
    },
    // 单价 V2
    getUnitPriceLabel: {
      get () {
        // let propFile = this.dataPropField || "params"
        // if ([3, 4].includes(this[propFile].contract_category)) {
        //   return "综合单价不含税（元）"
        // } else if ([6].includes(this[propFile].contract_category)) {
        //   return "不含税单价（元）/（元/米/天）"
        // } else {
        // }
        return "单价(元)";
      },
      set () {
      }
    },
    // 合价 V2
    getTotalPriceLabel: {
      get () {
        let propFile = this.dataPropField || "params";
        if ([6].includes(this[propFile].contract_category)) {
          return "合价(元)";
        } else {
          return "合价";
        }
      },
      set () {
      }
    },
    user () {
      return this.$store.state.user;
    }
  },
  methods: {
    // 待审核 已审核
    // 查看收方详情
    lookedDtl ({ contract_id = null, row_id = null, page_type = null }) {
      return new Promise((resolve, reject) => {
        if (row_id === null || contract_id === null) {
          this.$msg.fail("数据配置项错误");
          reject(null);
        } else {
          apiDailyContractDetail({ contract_id: contract_id, limit: 999999, page: 1 }, res => {
            let { code, data } = res.data;
            if (code === 200) {
              // let isBoj = Object.prototype.toString.call(row) === "[object Object]";
              // if (isBoj && ![NaN, undefined, null].includes(cellValue)) {
              //   return dayjs(cellValue*1000).format("YYYY-MM-DD HH:mm:ss");
              // } else {
              //   return "";
              // }
              data.list.data.forEach(el => {
                el.created_at = dayjs(Number(el.created_at) * 1000).format("YYYY-MM-DD HH:mm:ss");
              });
              let result = null;
              data.list.data.forEach((dl, d) => {
                dl.inl = d;
                if (Number(dl.id) === Number(row_id)) {
                  result = dl;
                }
              });
              // 找下标,找对应的对象
              let rrs = data.list.data.find((el, e) => {
                if (Number(el.id) === Number(row_id)) {
                  return el;
                }
              });
              console.log("rrs", rrs);
              if (result === null) {
                this.$msg.fail("暂无数据");
                reject(null);
              } else {
                if (page_type) {
                  resolve({ result, data: data.list.data });
                } else {
                  resolve(result);
                }
                
              }
            }
          });
        }
      });
    },
    // V1.3.2 判断是否可以编辑
    isV132Edit ({ row, dtl = null }) {
      if (dtl) {
        if (this.is_yg_super) {
          return {
            stt: row.pageStt === "wait",
            is_yg_super: this.is_yg_super,
            is_zg_super: false
          };
        }
        if (this.is_zg_super) {
          return {
            stt: row.pageStt === "wait" || row.pageStt === "audit",
            is_yg_super: false,
            is_zg_super: this.is_zg_super
          };
        }
      } else {
        if (this.is_yg_super) {
          return row.pageStt === "wait";
        }
        if (this.is_zg_super) {
          return row.pageStt === "wait" || row.pageStt === "audit";
        }
      }
    },
    // V2.3.2 新增逻辑 总工编辑时逻辑有大变化
    // |--1. 新增编辑按钮
    // |--2. 拆分之前审核编辑二合一功能变为单一接口
    // |--3. 点击编辑时，清空【当日收方量】 单价，【当日收方单价】按照浮动单价和固定单价来展示；
    // |-----3.1 都是取daily_price字段了
    isV232Edit ({ row, dtl = null, funcLocate = "", user_id = "" }) {
      // 不是总工的逻辑还是之前的逻辑
      // 总工点击编辑之后才是新版本的逻辑
      // 判断是不是总工 && 是待审核列表页来的
      // 不是总工
      if (this.is_zg_super && row.pageStt === "audit") {
        if (funcLocate === "BottomBtnArea") {
          return true;
        } else {
          // 根据按钮状态确定返回的状态值
          return row.formDisabled;
          // return false
        }
      } else {
        // 员工区域
        return this.isV132Edit({ row, dtl })||[this.user.id].includes(user_id);
      }
      
    },
    // 退出
    cancel () {
      this.$dialog.confirm({
        message: "确定退出吗?",
        confirmButtonColor: "#3C85DA"
      }).then(() => {
        // on confirm
        removeToken();
        removeTokenl();
        
        // 强制更新缓存
        var d = new Date();
        var time_ = String(d.getTime());
        this.$router.push({ path: "login", query: { R_KEY: time_ } });
        // window.location.reload()
      }).catch(() => {
        // on cancel
      });
    },
    // 获取元数据
    getListMetaPromise () {
      return new Promise(resolve => {
        apiListMeta(res => {
          let listNum = sessionStorage.getItem("listNum");
          if (!listNum) {
            listNum = 0;
          }
          listNum++;
          sessionStorage.setItem("listNum", listNum);
          if (res.data.code === 200) {
            this.$store.dispatch("getListMeta", res.data.data);
            resolve(res.data);
          }
        });
      });
    },
    // 获取用户信息
    getUserInfoPromise (token_) {
      return new Promise(resolve => {
        getUserInfo({ token: token_ }, res => {
          if (res.data.code === 200) {
            this.$store.dispatch("getUserInfo", res.data.data);
            sessionStorage.setItem("r_user", JSON.stringify(res.data.data));
            resolve(res.data);
          }
        });
      });
    },// 获取分类信息
    getCategoryPromise () {
      return new Promise(resolve => {
        category({ "uid": this.user ? this.user.id : "" }, res => {
          if (res.data.code === 200) {
            let contract_category = [];
            res.data.data.forEach(el => {
              el.label = el.field_mean;
              if (el.field_value !== "0") {
                contract_category.push(el);
              }
            });
            resolve(contract_category);
          }
        });
      });
    },
    getAll (token) {
      let getListMeta = this.getListMetaPromise();
      let getUserInfo = this.getUserInfoPromise(token);
      return new Promise((resolve, reject) => {
        Promise.all([getListMeta, getUserInfo]).then(res => {
          resolve(res);
        });
      });
    }
  },
  watch: {
    user: {
      handler (n, o) {
      },
      deep: true
    },
    "$route" (n, o) {
      if (n && n.hasOwnProperty("name")) {
        this.ePageName = n.name;
      }
    }
  }
};
