<template>
  <!--  <div class="bs-bb flex-box jcsb plr-30 ptb-15 tabBarBox h150">-->
  <!--    <slot class="" name="leftHandle"></slot>-->
  <!--    <slot class="flex1" name="headerTitle"></slot>-->
  <!--    <slot class="" name="rightHandle"></slot>-->
  <!--  </div> -->
  <div class="bs-bb flex-box jcsb pl30 pr30 pb15 pt15 tabBarBox h130">
    <slot class="" name="leftHandle">
      <div class="w120" @click="$router.go(-1)">
        <i class="iconfont icon-zuojiantou c-fff fs48"></i>
      </div>
    </slot>
    <slot class="flex1 fw-b c-fff flex-box jcc aic o-h" name="headerTitle">
      <div class="flex1 o-h t-c flex-box aic" v-if="user&&Object.keys(user).length>0">
        <span class="inline-block c-fff text-es o-h">{{user.user_name}}</span>
      </div>
    </slot>
    <slot class="" name="rightHandle">
      <div class="w120 t-r" @click="cancel">
        <i class="iconfont icon-tuichu c-fff fs40"></i>
      </div>
    </slot>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import receipt from '@/mixins/receipt'

export default {
  mixins: [receipt],
  data () {
    return {
      key: '',
    }
  },
  created () {},
  methods: {
    name () {

    }
  },
  computed: {
    listMeta () {
      // 新建时处理元数据
      let listMeta = this.$store.state.listMeta

      return this.$store.state.listMeta
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    listMeta: {
      handler (n, o) {
      },
      deep: true
    },
    user: {
      handler (n, o) {
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tabBarBox {
}
</style>
