import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
let intergalCarts = localStorage.getItem('intergalCarts');
if (intergalCarts) {
  intergalCarts = JSON.parse(localStorage.getItem('intergalCarts'));
} else {
  intergalCarts = {
    prdctTypeCode: '52301',
    to: 'single',
    single: [],
    self: [],
    selfInfo: {},
    singleInfo: {}
  };
}
const state = {
  errMsg: [],
  listMeta: {}, // 元数据
  user: {}, // 用户信息
  pageKey: +new Date(), // 页面key
};
state.intergalCarts = intergalCarts;
const mutations = {
  changeKey (state, value) {
    state.pageKey = value;
  },
  changeListMeta (state, value) {
    state.listMeta = value;
  },
  changeUser (state, value) {
    state.user = value;
  },
  changeStoreId (state, value) {
    state.storeId = value;
  },
  changePoints (state, value) {
    state.points = value;
  },
  // 更新积分信息
  changeIntergal (state, value) {
    state.integralInfo = value;
  }
};
const actions = {
  getListMeta ({commit}, value) {
    commit('changeListMeta', value);
  },
  getUserInfo ({commit}, value) {
    commit('changeUser', value);
  },
  getStoreId ({commit}, value) {
    commit('changeStoreId', value);
  }
};
const getters = {
};
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
