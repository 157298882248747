<template>
  <div class="">
    <van-tabbar v-model="active" active-color="#499CE1" inactive-color="#797979" @change="onChange"
                v-if="['audit123','audited123','write123'].includes($route.name)" class="TABBAR">
      <van-tabbar-item name="write" replace to="/write">
        待填写
        <template #icon="props">
          <i class="iconfont icon-write fontsize24"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="audit" replace to="/audit">
        待审核
        <template #icon="props">
          <i class="iconfont icon-shenhe2 fontsize24"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="audited" replace to="/audited">
        已审核
        <template #icon="props">
          <i class="iconfont icon-audited fontsize24"></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import h5 from '@/mixins/h5'

export default {
  mixins: [h5],
  data () {
    return {
      active: 'index'
    }
  },
  created () {
  },
  mounted () {
  },
  watch: {
    $route: {
      handler (n, o) {
        this.active = ['result'].includes(n.name) ? 'index' : n.name
      },
      deep: true
    }
  },
  methods: {
    onChange (val) {
      this.active = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/var.scss';
@import '@/assets/css/_mixin.scss';

.dj-footer {
  height: $footerH;
}
.TABBAR{
  background: #fff;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
/*.footer-placeholder {*/
/*  display: none;*/
/*  @media only screen and (min-height: 736px) {*/
/*    display: block;*/
/*    !*padding-bottom: .28rem;*!*/
/*  }*/
/*}*/

.dj-footer-style {
  box-shadow: 0 .5rem .5rem #000;
}
</style>
