import axios from "axios"
import { showFullScreenLoading, tryHideFullScreenLoading } from "./loading"
import router from "../router"
import { Toast } from "vant"
import Cookies from "js-cookie"
import { getToken, removeToken } from "@/utils/auth"

Toast.allowMultiple()

function toastBox (msg = "请求失败") {
  let toast = Toast.fail({
    message: msg,
    duration: 0
  })
  setTimeout(() => {
    toast.clear()
  }, 1500)
}

let http = axios.create({
  baseURL: "/api/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  transformRequest: [function(data) {
    return data
  }]
})
http.defaults.timeout = 15000
http.interceptors.request.use(function(config) {
  // Cookies
  let openId = localStorage.getItem("openId")
  if (openId) config.headers.openId = openId
  let currentTenantId = localStorage.getItem("currentTenantId")
  if (currentTenantId) config.headers.currentTenantId = currentTenantId
  if (getToken()) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["Authorization"] = "Bearer " + getToken()
  }
  // 通过session控制是否需要加载框
  let loading = localStorage.getItem("loading")
  if (!config.showLoading && loading === "0") {
    config.showLoading = true
    showFullScreenLoading()
  }
  return config
}, function(error) {
  return Promise.reject(error)
})
http.interceptors.response.use(function(response) {
  if (response.config.showLoading) {
    tryHideFullScreenLoading()
  }
  let code = response.data.code
  if (code === 200) {
    return response
  } else if (code === 401) {
    var d = new Date()
    var time_ = String(d.getTime())
    
    if (getToken) {
      toastBox("请重新登陆")
      router.push({ path: "/login", query: { R_KEY: time_ } })
    }
    return response
  } else {
    toastBox(response.data.message || response.data.msg)
    return response
    // return Promise.reject(response);
  }
}, function(error) {
  toastBox()
  tryHideFullScreenLoading()
  router.push({ path: "/error" })
  return Promise.reject(error)
})

export default http
