import http from "../utils/axios"
import { BASE, config, cb } from "./base"

// 项目列表
export function getTree (params, callback) {
  http(config(BASE + "erp/receiving/mobile/project", params)).then(res => cb(res, callback))
}

// 设置默认项目
export function setDefault (params, callback) {
  http(config(BASE + "erp/receiving/mobile/setDefault", params, "POST")).then(res => cb(res, callback))
}

// 每日收方列表
export function dailyContract (params, callback) {
  http(config(BASE + "erp/receiving/mobile/dailyContract", params)).then(res => cb(res, callback))
}

// 登陆
export function login (params, callback) {
  http(config(BASE + "erp/receiving/login", params, "POST")).then(res => cb(res, callback))
}

// 获取用户信息
export function getUserInfo (params, callback) {
  http(config(BASE + "erp/auth/receiving", params)).then(res => cb(res, callback))
}

// 合同类别
export function category (params, callback) {
  http(config(BASE + "receiving/category", params)).then(res => cb(res, callback))
}

// 详情页面
export function contractDtl (params, callback) {
  http(config(BASE + "erp/receiving/mobile/contract", params)).then(res => cb(res, callback))
}

// 总工审核
export function audit_zg (params, callback) {
  http(config(BASE + "erp/receiving/mobile/submit/zg", params, "POST")).then(res => cb(res, callback))
}
// 总工审核 - 使用PC端接口
export function IAuditZGPC (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractApprove", params, "POST")).then(res => cb(res, callback))
}
// 总工编辑时保存 - 使用PC端接口
export function IUpdatedZGPC (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractEdits/edit", params, "POST")).then(res => cb(res, callback))
}

// 总工收方明细编辑时保存 - 使用PC端接口
export function IUpdatedHistoryZGPC (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractEdits/history", params, "POST")).then(res => cb(res, callback))
}

// 材料劳务提交
export function audit_cl (params, callback) {
  http(config(BASE + "erp/receiving/mobile/submit/cl", params, "POST")).then(res => cb(res, callback))
}

// 每日收方新增
export function apiDailyContractEditsAdd (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractEdits/add", params, "POST")).then(res => cb(res, callback))
}

// 收方明细
export function apiDailyContractDetail (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractDetail", params)).then(res => cb(res, callback))
}

// 收方修改记录
export function apiDailyContractLog (params, callback) {
  http(config(BASE + "erp/receiving/dailyContractLog", params)).then(res => cb(res, callback))
}

// 手机端-合同明细详情
export function apiReceiveDetail (params, callback) {
  http(config(BASE + "erp/receiving/mobile/receiveDetail", params)).then(res => cb(res, callback))
}



