<template>
  <div id="app" :class="{'app-no-bgc':$route.name==='login','app-bgc':$route.name!=='login'}">
    <!--    <transition name="fade">-->
    <router-view v-if="isRouter"></router-view>
    <div>
      <p class="mtb-5" v-for="(item,i) in msg" :key="i">{{item}}</p>
    </div>
    <!--    </transition>-->
    <dj-footer></dj-footer>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { getQueryString } from '@/utils/util'
import {
  setToken,
  getToken,
  removeToken,
  removeTokenl,
  getTokenl,
  setTokenl
} from '@/utils/auth'
import mixin from '@/mixins/mixins'
import Cookie from 'js-cookie'
import { wxDomain } from '@/api/base'
import { apiGetToken, apiListMeta } from '@/api/BASE-API'
import { apiTypeName, apiAuth } from '@/api/h5'
import footer from '@/components/config/basis/footer.vue'
import { cb, wxApi } from './api/base'
import { getUserInfo } from '@/api/receipt'
import receipt from '@/mixins/receipt'

export default {
  mixins: [mixin, receipt],
  components: {
    djFooter: footer
  },
  data() {
    return {
      ePageName: '',
      indicator: null,
      value: 2,
      isRouter: false,
      msg: []
    }
  },
  created() {
    this.isRouter = true
    if (getToken() && this.ePageName !== 'login') {
      this.getAll(getToken()).then(r => {})
    }
  },
  methods: {
    goToHome() {
      let rediUrl = getQueryString('rediUrl') || Cookie.get('rediUrl')
      if (rediUrl) {
        this.$router.push({ path: decodeURIComponent(rediUrl) })
      } else {
        let code = getQueryString('code')
        if (code) window.location.href = wxDomain
      }
    },
    // 判断页面的状态
    judgePageStt() {
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          // audio.pause()
          // video.pause()
          this.msg.push(
            `${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了隐藏`
          )
        } else {
          this.msg.push(
            `${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS A')}页面执行了显示`
          )
          setTimeout(() => {
            // bgMusic.play()
            // video.play()
          }, 2000)
        }
      })
    },
    // 取消开始的loading动画
    offPreLoader () {
      const preLoader = document.querySelector("#pre-loader");
      if (preLoader) {
        preLoader.style.display = "none";
      }
      // fix: firefox 下 拖拽 会新打卡一个选项卡
      // https://github.com/JakHuang/form-generator/issues/15
      document.body.ondrop = event => {
        event.preventDefault();
        event.stopPropagation();
      };
    },
  },
  mounted() {
    this.offPreLoader();
  },
  computed: {
    listMeta() {
      // 新建时处理元数据
      let listMeta = this.$store.state.listMeta
      return listMeta
    }
  },
  watch: {
    listMeta: {
      handler(n, o) {},
      deep: true
    },
    $route(n, o) {
      this.toAndFrom = {
        n,
        o
      }
      if (n && n.hasOwnProperty('name')) {
        this.ePageName = n.name
      }
      localStorage.setItem('loading', '0')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/base.scss';
@import '@/assets/css/basev2.0.scss';
@import '@/assets/css/defaultGlobalElement.scss';

#app {
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-origin: border-box;
  background-clip: border-box;
  background-size: 100%;
  /*
    &.app-no-bgc {
      background: $color-bg url("../static/images/login-bgc.jpg") no-repeat -7.66rem 0;
      min-height: 100vh;
      overflow: auto;
      background-origin: border-box;
      background-clip: border-box;
      background-size: 202% 100%;
    }
    */
  &.app-no-bgc {
    background: $color-bg url('../static/images/login-bgc.jpg') no-repeat 100%
      100%;
    min-height: 100vh;
    overflow: auto;
    background-origin: border-box;
    background-clip: border-box;
    background-size: 100% 100%;
  }

  &.app-bgc {
    /*
    background-color: $color-bg;
    */
    background-color: #e9f2fa;
    background-image: url('../static/images/tabbar_bgc.jpg');
    background-repeat: no-repeat;
    background-position: 0 0;
    min-height: 100vh;
    overflow: auto;
    background-origin: border-box;
    background-clip: border-box;
    /*background-size: 100% 1.6rem;*/
    background-size: 100% 12%;
    background-attachment: fixed;
  }
}
</style>
