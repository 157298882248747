import Vue from "vue"
import router from "./router"
import store from "./vuex"
import filters from "./config/filter"
import App from "./App"
import axios from "./utils/axios"
import components from "./config/components"
import "./utils/direct"
// 导入 NProgress 对应的js和css
import NProgress from "nprogress"
import { Toast, Dialog } from "vant"

Vue.use(components)

import { apiJsError } from "@/api/h5"
import dayjs from "dayjs"

Vue.prototype.$msg = Toast
Vue.prototype.$dialog = Dialog
Vue.config.productionTip = false
// js报错拦截
Vue.config.errorHandler = function(err, vm, info) {
  var user_ = JSON.parse(localStorage.getItem("name"));
  let {
    message, // 异常信息
    name, // 异常名称
    script,  // 异常脚本url
    line,  // 异常行号
    column,  // 异常列号
    stack  // 异常堆栈信息
  } = err
  apiJsError({
    message: message,
    name: name,
    date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    fullPath: vm.$route.fullPath,
    wx_name: localStorage.getItem("name"),
    wx_uid: localStorage.getItem("wx_uid"),
    platform: "RECEIPT PROD",
    ERROR_: err.stack.toString()
  }, res => {
  })
  // vm为抛出异常的 Vue 实例
  // info为 Vue 特定的错误信息，比如错误所在的生命周期钩子
}

/* eslint-disable no-new */
// 使用过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

// 图标加载失效的问题
function loadFont () {
  let links = document.getElementsByTagName("link")
  if (links.length === 0) return
  let href = links[0].href + "?v=" + new Date().getTime()
  let head = document.getElementsByTagName("head")[0]
  let link = document.createElement("link")
  link.href = href
  link.rel = "stylesheet"
  link.type = "text/css"
  head.appendChild(link)
}

window.onload = function() {
  loadFont()
}
